import React from "react";
import Layout from "components/layout";
import PostBody from "components/PostBody";
import PostHeader from "components/PostHeader";
import LocalizedLink from "components/LocalizedLink";
import JobListing from "components/JobListing"
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import SEO from "components/seo";
import { colors } from "style/theme";
import { jsx } from "@emotion/core";
import { mq } from "utils/helper";

const Jobs = ({ data, location }) => {
  const { pageContent, jobListings } = data;

  return (
    <div
      css={{
        position: "relative",
        overflow: "hidden"
      }}
    >
      <Layout title={pageContent.title} css={{ zIndex: 2 }}>
        <SEO title={pageContent.title} />
        <img
          src="/assets/about-background.gif"
          css={{
            position: "absolute",
            top: 0,
            left: "30%",
            width: "60%",
            height: "auto",
            zIndex: 0,
            opacity: 0.35
          }}
        />
        <PostHeader post={pageContent} css={{ zIndex: 1 }} />
        <div
          css={{
            maxWidth: rhythm(34),
            marginBottom: rhythm(4),
            margin: "auto",
            position: "relative",
            zIndex: 1
          }}
        >
          <PostBody body={pageContent.content.data.content} css={{ zIndex: 1 }} />
          <h5
            css={{
              color: colors.light,
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "2rem"
            }}
          >
            Open positions:
          </h5>
          <div>
            {jobListings.edges.map(({ node }) => (
              <JobListing listing={node} key={node.id} />
            ))}
          </div>

        </div>
      </Layout>
    </div>
  );
}

export default Jobs;

export const pageQuery = graphql`
  query jobsPage {
    pageContent: strapiPage(
      slug: {eq: "about/jobs"}
    ) {
      title
      content {
        data {
          content
        }
      }
    }
    jobListings: allStrapiJob(
      filter: {
        active: {eq: true}
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          date
          location
          deadline
          active
        }
      }
    }
  }
`;
